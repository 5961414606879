import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../appContext";
import { ProfileContext } from "./profileContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Dots } from "react-activity";
import { uploadPhotos } from "../services/svcImages";
import { saveOrder } from "../services/svcOrders";
import { getAdvertiser, writeAdvertiser } from "../services/svcClient";
import { genID } from "../services/svcFunctions";
import { services } from "../config/typeNames";

const niceDate = (date) => {
  const d = new Date(date);
  return d.toLocaleString();
};

export default function CardOrderNew() {
  const navigate = useNavigate();
  const profile = useContext(ProfileContext);
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [searchParams] = useSearchParams();
  const clientID = searchParams.get("id");
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [badFile, setBadFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [client, setClient] = useState();
  const inputRef = useRef(null);
  const fileCount = useRef(0);
  const type = "paid";

  useEffect(() => {
    const getClientData = async () => {
      if (!clientID) {
        return;
      }
      const clientData = await getAdvertiser(clientID);
      if (!clientData) {
        return;
      }
      if (!("designCredits" in clientData)) {
        clientData.designCredits = 0;
      }
      setClient(clientData);
      setLoading(false);
    };
    getClientData();
  }, [clientID]);

  const submitOrder = async () => {
    setUploading(true);
    setSubmitDisabled(true);
    const userData = profile.profile.data;
    const docID = genID(12);
    const newNotes = [];
    const newNote = niceDate(Date.now()) + " CLIENT: " + description;
    newNotes.unshift(newNote);
    const data = {
      files: files,
      orderNotes: newNotes,
      clientName: client.clientName,
      clientID: client.clientID,
      userName: userData.first + " " + userData.last,
      userID: user.uid,
      couponType: type,
      id: docID,
      revisions: 3,
    };
    const newOrder = {
      clientID: clientID,
      data: data,
      submitted: new Date().getTime(),
    };
    await saveOrder(newOrder, docID);

    //--update client record to burn 1 design credit and add order to list
    const newClient = { ...client };
    newClient.designCredits = newClient.designCredits - 1;
    if (!("orders" in newClient)) {
      newClient.orders = [];
    }
    const orderItem = {
      type: services[type],
      orderID: docID,
      phase: "pending",
      submitted: new Date().getTime(),
    };
    newClient.orders.unshift(orderItem);

    //--write back the client record
    await writeAdvertiser(newClient);

    //--go to admin page
    navigate("/admin");
  };

  const dismissAlert = () => {
    setBadFile(false);
    setUploading(false);
  };

  const changeDescription = (e) => {
    setDescription(e.target.value);
    if (e.target.value.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const handleClick = () => {
    setUploading(true);
    //-- if user cancels, this turns the uploader back on after 8 seconds
    setTimeout(() => {
      if (uploading) setUploading(false);
    }, 8000);
    inputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const fileObj = e.target.files && e.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      setBadFile(true);
      return;
    }
    //-- check the file type
    if (fileObj.type !== "image/jpeg" && fileObj.type !== "image/png") {
      setBadFile(true);
      return;
    }
    //-- check the file size
    if (fileObj.size > 2000000) {
      setBadFile(true);
      return;
    }

    //-- upload the file
    setBadFile(false);
    await uploadFile(fileObj);

    //-- reset uploading
    setUploading(false);
  };

  const uploadFile = async (fileObj) => {
    try {
      //-- upload to storage
      const filename = await uploadPhotos(fileObj);

      //-- add file to array
      const newFiles = files;
      newFiles.unshift(filename);
      setFiles(newFiles);
      fileCount.current = fileCount.current + 1;
      return;

      //-- catch any errors
    } catch (err) {
      console.log("uploadPhoto: ERROR: " + err);
      setBadFile(true);
      setUploading(false);
    }
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Order Graphic Design</h3>
      </Row>
      <Row>
        <p>
          Our graphic designers are available to help you create a compelling
          offer image. You may request 2 additional revisions to each design
          created. They can view and use your current Design Assets.
        </p>
      </Row>
      <Row className="padded-top">
        <h5>Describe Your Design</h5>
      </Row>
      <Row className="order-row-top">
        <Form.Control
          as="textarea"
          rows={8}
          placeholder="Please include as much detail as possible."
          onChange={changeDescription}
          value={description}
        />
      </Row>
      <Row className="padded-top">
        <h5>Attach Images</h5>
        <p>
          Optional: include up to 3 JPEG or PNG images such as logos or products
          you would like to use in your design, 2MB max size each.
        </p>
      </Row>
      {files.length > 0 && (
        <React.Fragment>
          {files.map((file, index) => (
            <Row key={index} className="side-padding v-top-10">
              <Alert className="v-margin-reset" variant="success">
                Attached: {file}
              </Alert>
            </Row>
          ))}
        </React.Fragment>
      )}
      {badFile && (
        <Alert
          variant="danger"
          className="text-center"
          onClose={dismissAlert}
          dismissible
        >
          Sorry, file too big or wrong type
        </Alert>
      )}
      <Row className="side-padding v-top-20">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />
        <Button
          disabled={uploading || fileCount.current > 2}
          className="snagm-color"
          onClick={() => handleClick()}
        >
          {uploading ? (
            <Dots color="#ffffff" />
          ) : (
            "Optional - Upload Image File"
          )}
        </Button>
      </Row>
      {!loading && (
        <Row className="chart-top">
          <p className="text-center">Each request uses 1 design credit.</p>
          <p className="text-center">
            You have {client.designCredits} credits available.
          </p>
        </Row>
      )}
      <Row className="campaign-row">
        <Button
          disabled={
            submitDisabled || client.designCredits < 1 || type === "none"
          }
          className="button-campaign"
          onClick={() => submitOrder()}
        >
          Place Your Order
        </Button>
      </Row>
    </Col>
  );
}
