import React from "react";
import { useSearchParams } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardOrderNew from "./cardOrderNew";
import CardOrderView from "./cardOrderView";
import CardBuyCredits from "./cardBuyCredits";
import CardOrderManage from "./cardOrderManage";

export default function DashOrder() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("m");
  const id = searchParams.get("id");

  if (mode === "buy") {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col>
            <CardBuyCredits />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  if (mode === "new") {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col>
            <CardOrderNew />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  if (mode === "manage") {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col>
            <CardOrderManage id={id} />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardOrderView id={id} />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
