import React, { useState, useContext, useEffect } from "react";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardNew from "./cardNew";
import CardAdvertiserLibrary from "./cardAdvertiserLibrary";
import { ProfileContext } from "./profileContext";
import { AppContext } from "../appContext";
import { getAdvertiser, writeAdvertiser } from "../services/svcClient";
import { writeDocument } from "../services/firestore";
import { genID } from "../services/svcFunctions";

export default function DashNew() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const profile = useContext(ProfileContext);
  const [selectedImage, setSelectedImage] = useState("");
  const [clientData, setClientData] = useState({});

  useEffect(() => {
    const loadProfile = async () => {
      if (!profile) return null;
      const id = profile.profile.clientID;
      const data = await getAdvertiser(id);
      setClientData(data);
      console.log("CLIENT DATA", data);
    };
    loadProfile();
  }, [profile]);

  const changeImageFile = (filename) => {
    setSelectedImage(filename);
  };

  const changeClientData = (data) => {
    const result = writeAdvertiser(data);
    if (result) {
      setClientData(data);
    }
  };

  const submitCampaign = async (campaignData) => {
    const userProfile = profile.profile.data;
    const couponKey = userProfile.clientID + "-paid";
    const couponID = userProfile.clientID + "-" + genID(5);
    const updatedCampaign = { ...campaignData };
    updatedCampaign.file = selectedImage;
    updatedCampaign.clientID = userProfile.clientID;
    updatedCampaign.clientName = clientData.clientName;
    updatedCampaign.createdBy = user.uid;
    updatedCampaign.created = new Date().getTime();
    updatedCampaign.id = couponID;
    updatedCampaign.notify = userProfile.email;
    updatedCampaign.phase = "review";
    updatedCampaign.type = "paid";
    updatedCampaign.style = "image";
    updatedCampaign.behavior = "coupon";
    updatedCampaign.zipCode = clientData.zipCode;
    const finalRecord = {
      data: updatedCampaign,
    };
    console.log("FINAL RECORD", finalRecord);
    const result = await writeDocument("approvals", couponKey, finalRecord);
    return result;
  };

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardNew
            clientData={clientData}
            selectedImage={selectedImage}
            submitCampaign={submitCampaign}
          />
        </Col>
        <Col>
          <CardAdvertiserLibrary
            clientData={clientData}
            changeImageFile={changeImageFile}
            changeClientData={changeClientData}
          />
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
