import React from "react";
import Col from "react-bootstrap/Col";

export default function SubContactsMktView(props) {
  const { mktData } = props;

  return (
    <Col>
      <p className="tiny-text">Name:</p>
      <h6>{mktData.name}</h6>
      <p className="tiny-text">Email Address:</p>
      <h6>{mktData.email}</h6>
      <p className="tiny-text">Phone Number:</p>
      <h6>{mktData.phone}</h6>
    </Col>
  );
}
