import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../appContext";
import { ProfileContext } from "./profileContext";
import { Routes, Route } from "react-router-dom";
import NoPage from "./noPage";
import DashHome from "./dashHome";
import DashInvoices from "./dashInvoices";
import DashPayments from "./dashPayments";
import DashSettings from "./dashSettings";
import DashAssets from "./dashAssets";
import DashNew from "./dashNew";
import DashArchive from "./dashArchive";
import DashOrder from "./dashOrder";
import { getProfile } from "../services/svcUsers";

export default function AdminStack() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [profile, setProfile] = useState();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const loadProfile = async () => {
      const userRecord = await getProfile(user.uid);
      setProfile(userRecord);
    };
    loadProfile();
    setIsReady(true);
  }, [user]);

  if (isReady) {
    return (
      <ProfileContext.Provider value={{ profile }}>
        <Routes>
          <Route index element={<DashHome />} />
          <Route name="NewCampaign" path="new" element={<DashNew />} />
          <Route name="NewCampaign" path="archive" element={<DashArchive />} />
          <Route name="OrderCoupon" path="order/*" element={<DashOrder />} />
          <Route name="ManageAssets" path="assets" element={<DashAssets />} />
          <Route name="Invoices" path="invoices" element={<DashInvoices />} />
          <Route name="Payments" path="payments" element={<DashPayments />} />
          <Route name="Settings" path="settings" element={<DashSettings />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </ProfileContext.Provider>
    );
  }
}
