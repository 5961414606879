import React from "react";
import Col from "react-bootstrap/Col";

export default function SubContactsOwnView(props) {
  const { ownData } = props;

  return (
    <Col>
      <p className="tiny-text">Name:</p>
      <h6>{ownData.name}</h6>
      <p className="tiny-text">Email Address:</p>
      <h6>{ownData.email}</h6>
      <p className="tiny-text">Phone Number:</p>
      <h6>{ownData.phone}</h6>
    </Col>
  );
}
