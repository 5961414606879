import React, { useEffect, useContext, useState } from "react";
import { ProfileContext } from "./profileContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardCurrent from "./cardCurrent";
import CardAccount from "./cardAccount";
import CardOrders from "./cardOrders";

export default function DashHome() {
  const profile = useContext(ProfileContext);
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProfile = async () => {
      const data = profile.profile;
      setProfileData(data);
      setLoading(false);
    };
    loadProfile();
  }, [profile]);

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row"></Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardCurrent profileData={profileData} />
        </Col>
        <Col>
          <CardOrders profileData={profileData} />
        </Col>
        <Col>
          <CardAccount profileData={profileData} />
        </Col>
      </Row>
    </Container>
  );
}
