import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";

export default function SubContactsOwn(props) {
  const { ownData, processing, saveChanges } = props;
  const [owner, setOwner] = useState(ownData);

  const handleChangeOwner = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setOwner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Col>
      <p className="tiny-text">&nbsp;Name:</p>
      <Form.Control
        name="name"
        value={owner.name}
        onChange={handleChangeOwner}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Email Address:</p>
      <Form.Control
        name="email"
        value={owner.email}
        onChange={handleChangeOwner}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Phone Number:</p>
      <Form.Control
        name="phone"
        value={owner.phone}
        onChange={handleChangeOwner}
        className="form-white v-top-5 bottom"
      />
      <Row className="padded-row v-top-20">
        {processing ? (
          <Button className="snagm-color">
            <Dots color="#ffffff" size={18} animating={true} />
          </Button>
        ) : (
          <Button
            onClick={() => saveChanges("owner", owner)}
            className="snagm-color"
          >
            Save Changes
          </Button>
        )}
      </Row>
    </Col>
  );
}
