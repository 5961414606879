import { readDocument, updateDocument, writeDocument } from "./firestore";

export async function getClient(id) {
  try {
    const clientRecord = await readDocument("clients", id);
    const clientData = clientRecord.data;
    return clientData;
  } catch (error) {
    console.log("GET CLIENT ERROR: ", error);
    return null;
  }
}

export async function getAdvertiser(id) {
  if (!id) return null;
  try {
    const clientRecord = await readDocument("advertisers", id);
    const clientData = clientRecord.data;
    return clientData;
  } catch (error) {
    console.log("GET ADVERTISER ERROR READING ID: ", id);
    return null;
  }
}

export async function writeClient(data) {
  const clientData = { ...data };
  const clientRecord = {
    zip: clientData.zip,
    data: clientData,
    deployment: clientData.cityState,
  };
  try {
    const result = await writeDocument("clients", data.clientID, clientRecord);
    return result;
  } catch (error) {
    console.log("WRITE CLIENT ERROR: ", error);
    return null;
  }
}

export async function writeAdvertiser(data) {
  const clientData = { ...data };
  const clientRecord = { data: clientData };
  try {
    const result = await writeDocument(
      "advertisers",
      data.clientID,
      clientRecord
    );
    return result;
  } catch (error) {
    console.log("WRITE ADVERTISER ERROR: ", error);
    return null;
  }
}

export async function updateClient(id, data) {
  const clientRecord = {
    zip: data.zip,
    data: data,
    deployment: data.cityState,
  };
  const clientData = await updateDocument("clients", id, clientRecord);
  return clientData;
}

export async function lookupClient(id) {
  const clientRecord = await readDocument("client-lookup", id);
  const clientData = clientRecord.data;
  return clientData;
}
