import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getAdvertiser } from "../services/svcClient";

const orderPhaseList = {
  ready: "Your design is ready for review!",
  pending: "A designer is being assigned...",
  complete: "New design added to your library.",
  working: "We are working on your design...",
};

const niceDate = (dateString) => new Date(dateString).toLocaleDateString();

export default function CardOrders(props) {
  const { profileData } = props;
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [ordersList, setOrdersList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadOrders = async () => {
      if (!profileData) {
        return;
      }
      const data = profileData.data;
      setProfile(data);
      const clientData = await getAdvertiser(profile.clientID);
      if (clientData) {
        if ("orders" in clientData) {
          let orders = clientData.orders;
          orders.forEach((order) => {
            order.desc = orderPhaseList[order.phase];
          });
          setOrdersList(orders);
          console.log("orders", orders);
        }
      }
    };
    loadOrders();
    setLoaded(true);
  }, [profileData, profile.clientID]);

  const goOrder = (phase, id) => {
    if (id === undefined) {
      id = "";
    }
    let goMode = phase;
    if (phase === "ready") {
      goMode = "manage";
    }
    if (phase === "pending" || phase === "completed" || phase === "working") {
      goMode = "view";
    }
    navigate({
      pathname: "order",
      search: createSearchParams({
        id: id,
        p: phase,
        m: goMode,
      }).toString(),
    });
  };

  const createSummary = (order) => {
    let summary = "";
    if (order.phase === "complete") {
      summary = order.type + " - Completed: " + niceDate(order.completed);
      return summary;
    } else {
      summary = order.type + " - Requested: " + niceDate(order.submitted);
    }
    return summary;
  };

  return (
    <Col className="dash-card">
      <Row className="small-bottom">
        <h3>Design Orders</h3>
      </Row>
      {ordersList.length === 0 && (
        <Row>
          <p className="text-center">No design orders on file.</p>
        </Row>
      )}
      {loaded && (
        <React.Fragment>
          {ordersList.map((order, index) => (
            <Row key={index}>
              <p
                onClick={() => goOrder(order.phase, order.orderID)}
                className="link-style"
              >
                {createSummary(order)}
              </p>
              <p>{order.desc}</p>
              {index < ordersList.length - 1 ? <p>-</p> : null}
            </Row>
          ))}
        </React.Fragment>
      )}
      <Row className="campaign-row v-top-10">
        <Button
          onClick={() => goOrder("new", profile.clientID)}
          className="button-campaign"
        >
          Place an Order
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button onClick={() => goOrder("buy")} className="button-campaign">
          Purchase Design Credits
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button onClick={() => navigate("assets")} className="button-campaign">
          Manage Design Assets
        </Button>
      </Row>
    </Col>
  );
}
