import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export default function SubContactsBilling(props) {
  const { billingData, processing, saveChanges } = props;
  const [billing, setBilling] = useState(billingData);

  const handleChangeBilling = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setBilling((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Col>
      <p className="tiny-text">&nbsp;Name:</p>
      <Form.Control
        name="name"
        value={billing.name}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Email Address:</p>
      <Form.Control
        name="email"
        value={billing.email}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Phone Number:</p>
      <Form.Control
        name="phone"
        value={billing.phone}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Address Line 1:</p>
      <Form.Control
        name="addr1"
        value={billing.addr1}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Address Line 2:</p>
      <Form.Control
        name="addr2"
        value={billing.addr2}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;City</p>
      <Form.Control
        name="city"
        value={billing.city}
        onChange={handleChangeBilling}
        className="form-white v-top-5 bottom"
      />
      <Row className="d-flex v-top-10">
        <Col>
          <p className="tiny-text">&nbsp;State</p>
          <Form.Select
            name="state"
            value={billing.state}
            onChange={handleChangeBilling}
            className="form-white"
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <p className="tiny-text">&nbsp;Zip: </p>
          <Form.Control
            name="zip"
            value={billing.zip}
            onChange={handleChangeBilling}
            className="form-white"
          />
        </Col>
      </Row>
      <Row className="padded-row v-top-20">
        {processing ? (
          <Button className="snagm-color">
            <Dots color="#ffffff" size={18} animating={true} />
          </Button>
        ) : (
          <Button
            onClick={() => saveChanges("billing", billing)}
            className="snagm-color"
          >
            Save Changes
          </Button>
        )}
      </Row>
    </Col>
  );
}
