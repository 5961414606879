import React, { useState, useEffect, useRef, useCallback } from "react";
import "../css/cards.css";
import "react-activity/dist/library.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Dots } from "react-activity";
import { uploadImage } from "../services/svcImages";
import { makeLink } from "../services/svcFunctions";

export default function CardLibrary(props) {
  const { changeImageFile, clientData, changeClientData } = props;
  const [images, setImages] = useState([]);
  const [ready, setReady] = useState(false);
  const [badFile, setBadFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const inputRef = useRef(null);

  const setLibrary = useCallback(async () => {
    if (!clientData) return null;
    if ("library" in clientData) {
      const library = [...clientData.library];
      setImages(library);
    }
    setReady(true);
    return null;
  }, [clientData]);

  const uploadFile = async (fileObj) => {
    try {
      //-- upload to storage
      const filename = await uploadImage(fileObj);

      //-- add to client library
      const newClientData = clientData;
      if (!("library" in newClientData)) {
        newClientData.library = [];
      }
      const newLibrary = newClientData.library;
      newLibrary.unshift(filename);
      newClientData.library = newLibrary;
      changeClientData(newClientData);

      //-- add new object to images array
      const newImages = [...images];
      newImages.unshift(filename);
      setImages(newImages);
      setUploading(false);
      setUploadDisabled(false);
      setUploadError(false);

      //-- catch any errors
    } catch (err) {
      console.log("uploadFile: ERROR: " + err);
      setUploadError(true);
      setUploading(false);
      setUploadDisabled(false);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      return;
    }
    //-- check the file type
    if (fileObj.type !== "image/jpeg") {
      setBadFile(true);
      return;
    }
    //-- check the file size
    if (fileObj.size > 500000) {
      setBadFile(true);
      return;
    }
    //-- check the image dimensions
    const objectURL = URL.createObjectURL(fileObj);
    const img = document.createElement("img");
    img.onload = () => {
      if (img.width === 1080 && img.height === 1080) {
        setBadFile(false);
        setUploadDisabled(true);
        setUploading(true);
        uploadFile(fileObj);
      } else {
        setBadFile(true);
      }
      URL.revokeObjectURL(img.src);
    };
    img.src = objectURL;
    event.target.value = null;
  };

  useEffect(() => {
    const startNow = async () => {
      setLibrary();
    };
    startNow();
  }, [clientData, setLibrary]);

  if (!ready) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <Col className="d-flex grow-5">
            <h3>Images Library</h3>
          </Col>
          <Col className="d-flex justify-content-end"></Col>
        </Row>
        <Row>
          <Dots className="text-center" size={32} color="#333" />
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <Col className="d-flex grow-5">
          <h3>Images Library</h3>
        </Col>
        <Col className="d-flex justify-content-end"></Col>
      </Row>
      <Row className="campaign-row small-bottom">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />
        <Button
          disabled={uploadDisabled}
          className="med-top snagm-color"
          onClick={() => handleClick()}
        >
          {uploading ? <Dots color="#ffffff" /> : "Upload Your Own"}
        </Button>
        {badFile ? (
          <Alert variant="danger" className="text-center">
            must be .JPG 1080x1080 less than 1 MB
          </Alert>
        ) : (
          <Alert className="text-center">
            must be .JPG 1080x1080 less than 1 MB
          </Alert>
        )}
        {uploadError && (
          <Alert variant="danger" className="text-center">
            OOPS... that didn't work
          </Alert>
        )}
        <Row className="margin-sides-sm med-top big-bottom text-center">
          <p>or click any image below to select:</p>
        </Row>
      </Row>

      {images.length === 0 && (
        <Row className="margin-sides-sm text-center">
          <Alert variant="light">No images saved yet!</Alert>
        </Row>
      )}
      {images.map((filename) => (
        <Row
          onClick={() => changeImageFile(filename)}
          className="big-bottom"
          key={filename}
        >
          <Image src={makeLink("images", filename)} />
        </Row>
      ))}
    </Col>
  );
}
