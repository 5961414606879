import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function SubContactsBillingView(props) {
  const { billingData } = props;

  return (
    <Col>
      <p className="tiny-text">Name:</p>
      <h6>{billingData.name}</h6>
      <p className="tiny-text">Email Address:</p>
      <h6>{billingData.email}</h6>
      <p className="tiny-text">Phone Number:</p>
      <h6>{billingData.phone}</h6>
      <p className="tiny-text">Address Line 1:</p>
      <h6>{billingData.addr1}</h6>
      <p className="tiny-text">Address Line 2:</p>
      <h6>{billingData.addr2}</h6>
      <p className="tiny-text">City</p>
      <h6>{billingData.city}</h6>
      <Row className="d-flex v-top-10 big-bottom">
        <Col>
          <p className="tiny-text">State</p>
          <h6>{billingData.state}</h6>
        </Col>
        <Col>
          <p className="tiny-text">Zip: </p>
          <h6>{billingData.zip}</h6>
        </Col>
      </Row>
    </Col>
  );
}
