import React, { useState, useEffect } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { getAdvertiser } from "../services/svcClient";
import { makeLink } from "../services/svcFunctions";

export default function CardSettings(props) {
  const { clientID } = props;
  const [clientData, setClientData] = useState({});
  const [logoURL, setLogoURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getClientData = async () => {
      if (!clientID) return;
      const clientRecord = await getAdvertiser(clientID);
      if (clientRecord) {
        if (!("location" in clientRecord)) clientRecord.location = {};
        if (!("addr1" in clientRecord.location))
          clientRecord.location.addr1 = "";
        if (!("addr2" in clientRecord.location))
          clientRecord.location.addr2 = "";
        if (!("addr3" in clientRecord.location))
          clientRecord.location.addr3 = "";
        setClientData(clientRecord);
        const logoURL = makeLink("images", clientRecord.logo);
        setLogoURL(logoURL);
        setLoading(false);
      }
    };
    getClientData();
  }, [clientID]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Main Settings</h3>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row>
        <h3>Main Settings</h3>
      </Row>
      <Row>
        <p className="big-bottom">
          Please contact us at <b>support@snagm.com</b> to request changes to
          any of these.
        </p>
      </Row>
      <Row className="small-bottom v-top-20">
        <p>Business Name:</p>
      </Row>
      <Row className="big-bottom">
        <p>
          <b>{clientData.clientName}</b>
        </p>
      </Row>
      <Row className="small-bottom">
        <p>Logo Banner:</p>
      </Row>
      <Row className="big-bottom">
        <Image src={logoURL} />
      </Row>
      <Row className="small-bottom">
        <p>Business Address:</p>
      </Row>
      <Row>
        <p>
          <b>{clientData.location.addr1 ? clientData.location.addr1 : ""}</b>
        </p>
        <p>
          <b>{clientData.location.addr2 ? clientData.location.addr2 : ""}</b>
        </p>
        <p>
          <b>{clientData.location.addr3 ? clientData.location.addr3 : ""}</b>
        </p>
      </Row>
    </Col>
  );
}
