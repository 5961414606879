import { readDocument, queryCollection } from "./firestore";

export async function getProfile(uid) {
  const profile = await readDocument("users", uid);
  return profile;
}

export async function getDesigner(id) {
  try {
    console.log("GETTING DESIGNER ID: ", id);
    const profile = await readDocument("designers", id);
    return profile;
  } catch (error) {
    console.log("ERROR GETTING DESIGNER: ", error);
    return null;
  }
}

export async function getUserList(clientID) {
  const resultsList = [];
  const userList = await queryCollection("users", "clientID", clientID);
  userList.forEach((user) => {
    resultsList.push(user.data);
  });
  resultsList.sort((a, b) => {
    return b.added - a.added;
  });
  return resultsList;
}

export async function getValidators(clientID) {
  const resultsList = [];
  const userList = await queryCollection("validators", "clientID", clientID);
  userList.forEach((user) => {
    resultsList.push(user.data);
  });
  resultsList.sort((a, b) => {
    return b.added - a.added;
  });
  return resultsList;
}
