import React, { useEffect, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAdvertiser, writeAdvertiser } from "../services/svcClient";
import SubContactsBilling from "./subContactsBilling";
import SubContactsBillingView from "./subContactsBillingView";
import SubContactsMkt from "./subContactsMkt";
import SubContactsMktView from "./subContactsMktView";
import SubContactsOwn from "./subContactsOwn";
import SubContactsOwnView from "./subContactsOwnView";
import IonIcon from "@reacticons/ionicons";

export default function CardContacts(props) {
  const { clientID } = props;
  const [advertiserData, setAdvertiserData] = useState();
  const [showBilling, setShowBilling] = useState(false);
  const [showMkt, setShowMkt] = useState(false);
  const [showOwn, setShowOwn] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContacts = async () => {
      const response = await getAdvertiser(clientID);
      if (!("contacts" in response)) {
        response.contacts = {};
      }
      if (!("billing" in response.contacts)) {
        response.contacts.billing = {};
      }
      if (!("marketing" in response.contacts)) {
        response.contacts.marketing = {};
      }
      if (!("owner" in response.contacts)) {
        response.contacts.owner = {};
      }
      setAdvertiserData(response);
      setLoading(false);
    };
    if (clientID) {
      loadContacts();
    }
  }, [clientID]);

  const saveChanges = async (type, data) => {
    setProcessing(true);
    const updatedAdvertiserData = { ...advertiserData };
    let contacts = {};
    if ("contacts" in updatedAdvertiserData) {
      contacts = { ...updatedAdvertiserData.contacts };
    }
    if (type === "billing") {
      const newBilling = { ...data };
      Object.keys(newBilling).forEach((key) => {
        if (newBilling[key] === "") {
          delete newBilling[key];
        }
      });
      contacts.billing = newBilling;
    }
    if (type === "marketing") {
      const newMarketing = { ...data };
      Object.keys(newMarketing).forEach((key) => {
        if (newMarketing[key] === "") {
          delete newMarketing[key];
        }
      });
      contacts.marketing = newMarketing;
    }
    if (type === "owner") {
      const newOwner = { ...data };
      Object.keys(newOwner).forEach((key) => {
        if (newOwner[key] === "") {
          delete newOwner[key];
        }
      });
      contacts.owner = newOwner;
    }
    updatedAdvertiserData.contacts = contacts;
    const response = await writeAdvertiser(updatedAdvertiserData);
    if (response) {
      setAdvertiserData(updatedAdvertiserData);
    }
    setProcessing(false);
    setShowBilling(false);
    setShowMkt(false);
    setShowOwn(false);
  };

  const toggleBilling = () => {
    setShowBilling(!showBilling);
  };

  const toggleMkt = () => {
    setShowMkt(!showMkt);
  };

  const toggleOwn = () => {
    setShowOwn(!showOwn);
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Contact Info</h3>
        </Row>
        <Row className="big-bottom">
          <h5>Billing Contact:</h5>
        </Row>
        <Row className="big-bottom v-top-20">
          <h5>Marketing:</h5>
        </Row>
        <Row className="big-bottom">
          <h5>Owner / Administrator:</h5>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Contact Info</h3>
      </Row>
      <Row className="d-flex bottom">
        <Col>
          <h5>Billing:</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <IonIcon
            name="pencil"
            className="edit-icon"
            onClick={() => toggleBilling()}
          />
        </Col>
      </Row>
      {showBilling ? (
        <SubContactsBilling
          billingData={advertiserData.contacts.billing}
          processing={processing}
          saveChanges={saveChanges}
        />
      ) : (
        <SubContactsBillingView billingData={advertiserData.contacts.billing} />
      )}
      <Row className="d-flex bottom">
        <Col>
          <h5>Marketing:</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <IonIcon
            name="pencil"
            className="edit-icon"
            onClick={() => toggleMkt()}
          />
        </Col>
      </Row>
      {showMkt ? (
        <SubContactsMkt
          mktData={advertiserData.contacts.marketing}
          processing={processing}
          saveChanges={saveChanges}
        />
      ) : (
        <SubContactsMktView mktData={advertiserData.contacts.marketing} />
      )}
      <Row className="d-flex bottom v-top-20">
        <Col>
          <h5>Account Owner:</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <IonIcon
            name="pencil"
            className="edit-icon"
            onClick={() => toggleOwn()}
          />
        </Col>
      </Row>
      {showOwn ? (
        <SubContactsOwn
          ownData={advertiserData.contacts.owner}
          processing={processing}
          saveChanges={saveChanges}
        />
      ) : (
        <SubContactsOwnView ownData={advertiserData.contacts.owner} />
      )}
    </Col>
  );
}
