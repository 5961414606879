import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";

export default function CardAccount(props) {
  const navigate = useNavigate();
  const { profileData } = props;
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profileData) {
      setProfile(profileData.data);
      setLoading(false);
    } else {
      console.log("NO PROFILE DATA");
    }
  }, [profileData]);

  const goScreen = (urlTarget) => {
    navigate(urlTarget);
  };

  if (loading) {
    return (
      <Col className="dash-card big-bottom">
        <Row>
          <h3>System Management</h3>
        </Row>
        <Row className="text-center">
          <Dots color="#88888" size={32} speed={1} animating={true} />
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card big-bottom">
      <Row className="small-bottom">
        <h3>System Management</h3>
      </Row>
      <Row className="big-bottom">
        <p className="text-center">
          Signed In: {profile.first}&nbsp;{profile.last}
        </p>
      </Row>
      <Row className="campaign-row">
        <Button
          className="button-campaign"
          onClick={() => goScreen("settings")}
        >
          Account Settings
        </Button>
        <Button className="button-campaign" onClick={() => goScreen("assets")}>
          Billing Details
        </Button>
      </Row>
    </Col>
  );
}
