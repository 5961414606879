import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";

export default function SubContactsMkt(props) {
  const { mktData, processing, saveChanges } = props;
  const [marketing, setMarketing] = useState(mktData);

  const handleChangeMarketing = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMarketing((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Col>
      <p className="tiny-text">&nbsp;Name:</p>
      <Form.Control
        name="name"
        value={marketing.name}
        onChange={handleChangeMarketing}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Email Address:</p>
      <Form.Control
        name="email"
        value={marketing.email}
        onChange={handleChangeMarketing}
        className="form-white v-top-5 bottom"
      />
      <p className="tiny-text">&nbsp;Phone Number:</p>
      <Form.Control
        name="phone"
        value={marketing.phone}
        onChange={handleChangeMarketing}
        className="form-white v-top-5 bottom"
      />
      <Row className="padded-row v-top-20">
        {processing ? (
          <Button className="snagm-color">
            <Dots color="#ffffff" size={18} animating={true} />
          </Button>
        ) : (
          <Button
            onClick={() => saveChanges("marketing", marketing)}
            className="snagm-color"
          >
            Save Changes
          </Button>
        )}
      </Row>
    </Col>
  );
}
