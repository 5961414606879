import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import {
  makeLink,
  dateIsValid,
  isoToday,
  makeISO,
  isoDate,
} from "../services/svcFunctions";
import noImage from "../assets/advertiser-scroll.jpg";
import { Alert } from "react-bootstrap";
import { Dots } from "react-activity";

const initInvoice = {
  amount: 0,
  payable: 0,
  creditAvailable: 0,
};

const initCampaign = {
  link: "",
  nickname: "",
  title: "",
  tag: "",
  terms: "",
  starts: "",
  ends: "",
  miles: 5,
  amount: 0,
  payable: 0,
};

const milesOptions = [
  { value: 5, label: "5 Miles" },
  { value: 10, label: "10 Miles" },
  { value: 15, label: "15 Miles" },
  { value: 20, label: "20 Miles" },
  { value: 25, label: "25 Miles" },
];

const ratePerZip = 0.1;

export default function CardNew(props) {
  const navigate = useNavigate();
  const { clientData, selectedImage, submitCampaign } = props;
  const [newCampaign, setNewCampaign] = useState(initCampaign);
  const [invoice, setInvoice] = useState(initInvoice);
  //-- set the start date for 3 days from today and then the end date 30 days after that
  const [dateInputs, setDateInputs] = useState({
    starts: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
    ends: new Date(new Date().getTime() + 33 * 24 * 60 * 60 * 1000),
  });
  const [errorList, setErrorList] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const startTime = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
    const endTime = startTime + 30 * 24 * 60 * 60 * 1000;
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const isoStart = makeISO(startDate);
    const isoEnd = makeISO(endDate);
    const newStart = isoDate(isoStart);
    const newEnd = isoDate(isoEnd);
    const updatedDatesInputs = {};
    updatedDatesInputs.starts = newStart;
    updatedDatesInputs.ends = newEnd;
    setDateInputs(updatedDatesInputs);

    //-- let's set up the invoice data
    const initInvoice = {
      clientID: clientData.clientID,
      date: isoToday(),
      amount: 0,
      status: "pending",
      type: "paid",
      miles: 5,
      days: 30,
      zipCount: 25,
      radius: [],
      creditAvailable: 15,
    };
    const updatedInvoice = calculateRate(initInvoice);
    setInvoice(updatedInvoice);
  }, [clientData]);

  const formatAsMoney = (amount) => {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setNewCampaign((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDates = (e) => {
    const { name, value } = e.target;
    setDateInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    const updatedCampaign = { ...newCampaign };
    if (!dateIsValid(value)) {
      updatedCampaign[name] = "";
    } else {
      //--update the number of days as an integer on the invoice
      const updatedInvoice = { ...invoice };
      const start = new Date(dateInputs.starts);
      const end = new Date(value);
      const diff = end - start;
      const days = diff / (1000 * 60 * 60 * 24);
      console.log("DAYS", days);
      updatedInvoice.days = days;
      //-- recalulate the invoice
      const updatedInvoice2 = calculateRate(updatedInvoice);
      setInvoice(updatedInvoice2);
    }
    const isoDate = value.replace(/-/g, "");
    updatedCampaign[name] = isoDate;
    setNewCampaign(updatedCampaign);
  };

  const handleSubmit = async () => {
    const errors = [];
    if (newCampaign.nickname === "") {
      errors.push("Internal Nickname is required");
    }
    if (newCampaign.title === "") {
      errors.push("Campaign Title is required");
    }
    if (newCampaign.tag === "") {
      errors.push("Campaign Tag is required");
    }
    if (newCampaign.terms === "") {
      errors.push("Campaign Terms are required");
    }
    if (newCampaign.starts === "") {
      errors.push("Campaign Start Date is required");
    }
    if (newCampaign.ends === "") {
      errors.push("Campaign End Date is required");
    }
    if (selectedImage === "") {
      errors.push("Campaign Image is required");
    }

    //-- now check that the end date is after the start date
    if (newCampaign.starts !== "" && newCampaign.ends !== "") {
      const start = new Date(newCampaign.starts);
      const end = new Date(newCampaign.ends);
      if (end < start) {
        errors.push("Campaign End Date must be after Start Date");
      }
    }
    //-- now check that the start date is after today
    if (newCampaign.starts !== "") {
      const start = new Date(newCampaign.starts);
      const today = new Date();
      if (start < today) {
        errors.push("Campaign Start Date must be after today");
      }
    }
    //-- now check that the difference between the start and end date is 90 days or less
    if (newCampaign.starts !== "" && newCampaign.ends !== "") {
      const start = new Date(newCampaign.starts);
      const end = new Date(newCampaign.ends);
      const diff = end - start;
      const days = diff / (1000 * 60 * 60 * 24);
      if (days > 90) {
        errors.push("Campaign cannot be longer than 90 days");
      }
    }
    if (errors.length > 0) {
      setErrorList(errors);
      setShowErrors(true);
    } else {
      setProcessing(true);
      setShowErrors(false);
      const result = await submitCampaign(newCampaign);
      if (result) {
        setSuccess(true);
      } else {
        setSuccess(false);
        const message =
          "Sorry, something weird happened - please try again later!";
        setErrorList([message]);
        setShowErrors(true);
        console.log(
          "Error submitting campaign: " + JSON.stringify(newCampaign)
        );
        setProcessing(false);
      }
    }
  };

  const handleChangeMiles = (e) => {
    const { value } = e.target;
    const updatedCampaign = { ...newCampaign };
    updatedCampaign.miles = value;
    setNewCampaign(updatedCampaign);
  };

  const calculateRate = (invoiceData) => {
    const updatedInvoice = { ...invoiceData };
    //const zipCount = updatedInvoice.zips.length;
    const rate = updatedInvoice.zipCount * ratePerZip * updatedInvoice.days;
    const amountDue = updatedInvoice.creditAvailable - rate;
    if (amountDue > 0) {
      updatedInvoice.amount = rate;
      updatedInvoice.payable = 0;
      updatedInvoice.creditUsed = rate;
    } else {
      updatedInvoice.amount = rate;
      updatedInvoice.payable = Math.abs(amountDue);
      updatedInvoice.creditUsed = updatedInvoice.creditAvailable;
    }
    console.log("UPDATED INVOICE", updatedInvoice);
    return updatedInvoice;
  };

  const handleFinish = () => {
    navigate("/admin");
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>New Coupon</h3>
      </Row>
      <Form className="big-bottom">
        <Row className="form-label">
          <p>
            <b>Internal Nickname</b>
          </p>
        </Row>
        <Row className="padded-row big-bottom">
          <Form.Control
            name="nickname"
            value={newCampaign.nickname}
            onChange={handleChangeData}
            placeholder="For your reference only"
          />
        </Row>
        <Row className="form-label">
          <p>
            <b>Business Web Site</b>
          </p>
        </Row>
        <Row className="padded-row big-bottom">
          <Form.Control
            name="link"
            value={newCampaign.link}
            onChange={handleChangeData}
            placeholder="https://www.yourwebsite.com"
          />
        </Row>
        <Row className="form-label">
          <p>
            <b>Headline</b>
          </p>
        </Row>
        <Row className="padded-row big-bottom">
          <Form.Control
            name="title"
            value={newCampaign.title}
            onChange={handleChangeData}
            placeholder="Large text at top of coupon"
          />
        </Row>
        <Row className="form-label">
          <p>
            <b>Tagline</b>
          </p>
        </Row>
        <Row className="padded-row big-bottom">
          <Form.Control
            name="tag"
            value={newCampaign.tag}
            onChange={handleChangeData}
            placeholder="Smaller text below headline"
          />
        </Row>
        <Row className="form-label">
          <p>
            <b>Terms and Conditions</b>
          </p>
        </Row>
        <Row className="padded-row big-bottom">
          <Form.Control
            name="terms"
            value={newCampaign.terms}
            onChange={handleChangeData}
            placeholder="Limit 1 per customer..."
          />
        </Row>
        <Row className="form-label">
          <p>
            <b>Image</b>
          </p>
        </Row>
        <Row className="v-top-10 padded-row big-bottom">
          <Image
            className="image-link-style"
            src={
              selectedImage === "" ? noImage : makeLink("images", selectedImage)
            }
          />
        </Row>
        <Row className="form-label">
          <p>
            &nbsp;&nbsp;<b>Publishing Dates</b>
          </p>
        </Row>
        <Row className="d-flex form-label padded-row">
          <Col className="d-flex align-items-center justify-content-end">
            <p>Start Date:</p>
          </Col>
          <Col>
            <Form.Control
              type="date"
              name="starts"
              value={dateInputs.starts}
              onChange={handleDates}
            />
          </Col>
        </Row>
        <Row className="d-flex form-label padded-row dates-left">
          <Col className="d-flex align-items-center justify-content-end">
            <p>End Date:</p>
          </Col>
          <Col>
            <Form.Control
              type="date"
              name="ends"
              value={dateInputs.ends}
              onChange={handleDates}
            />
          </Col>
        </Row>
        <Row className="form-label">
          <p>
            &nbsp;&nbsp;<b>Target Area</b>
          </p>
        </Row>
        <Row className="form-label v-top-10 padded-row">
          <Col className="d-flex align-items-center justify-content-end">
            <p>Max Distance:</p>
          </Col>
          <Col>
            <Form.Select
              onChange={(targetData) => handleChangeMiles(targetData)}
            >
              {milesOptions.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className="form-label big-top extra-padded-row">
          <hr />
        </Row>
        <Row className="form-label big-top padded-row">
          <Col>
            <p className="costing-text">Total Days:</p>
          </Col>
          <Col>
            <p className="costing-text">{invoice.days}&nbsp;</p>
          </Col>
        </Row>
        <Row className="form-label v-top-10 padded-row">
          <Col>
            <p className="costing-text">Target Zip Codes:</p>
          </Col>
          <Col>
            <p className="costing-text">{invoice.zipCount}&nbsp;</p>
          </Col>
        </Row>
        <Row className="form-label v-top-10 padded-row">
          <Col>
            <p className="costing-text">Invoice Total:</p>
          </Col>
          <Col>
            <p className="costing-text">({formatAsMoney(invoice.amount)})</p>
          </Col>
        </Row>
        <Row className="form-label v-top-10 padded-row">
          <Col>
            <p className="costing-text">Available Credit:</p>
          </Col>
          <Col>
            <p className="costing-text">
              {formatAsMoney(invoice.creditAvailable)}
            </p>
          </Col>
        </Row>
        <Row className="form-label v-top-10 padded-row">
          <Col>
            <p className="costing-text">
              <b>Payable Amount: </b>
            </p>
          </Col>
          <Col>
            <p className="costing-text">
              <b>{formatAsMoney(invoice.payable)}</b>
            </p>
          </Col>
        </Row>
      </Form>
      {success ? (
        <Row className="extra-padded-row">
          <Alert variant="success" className="text-center">
            Successfully Submitted!
          </Alert>
          <Button onClick={() => handleFinish()} className="snagm-color">
            OK
          </Button>
        </Row>
      ) : (
        <Row className="extra-padded-row">
          {showErrors && (
            <Alert variant="danger" className="text-center">
              {errorList.map((error, index) => {
                return (
                  <Row key={index}>
                    <p>{error}</p>
                  </Row>
                );
              })}
            </Alert>
          )}
          <Row className="super-padded-row">
            <p className="tiny-text center-me">
              ** Invoice Total will be charged to account once approved to
              publish. **
            </p>
          </Row>
          {processing ? (
            <Button className="snagm-color big-top">
              <Dots color="#ffffff" size={18} speed={1} animating={true} />
            </Button>
          ) : (
            <Button
              onClick={() => handleSubmit()}
              className="snagm-color big-top"
            >
              Submit To Publish
            </Button>
          )}
        </Row>
      )}
    </Col>
  );
}
