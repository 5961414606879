import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import { readDocument, addDocument } from "../services/firestore";
import { Dots } from "react-activity";
import { makeLink, niceDate, makeISO } from "../services/svcFunctions";
import { Chart } from "react-google-charts";
import EmptyChart from "../assets/empty-chart.jpg";

const options = {
  legend: { position: "bottom" },
  isStacked: false,
  colors: ["#6F9DE1"],
  vAxis: { minValue: 0 },
};

const chartHeader = ["Month", "Redemptions"];

const extractChartData = (redeems) => {
  const chartData = [];
  for (let i = 0; i < redeems.length; i++) {
    const redeem = redeems[i];
    const month = redeem.month;
    const count = redeem.count;
    chartData.push([month, count]);
  }
  chartData.unshift([...chartHeader]);
  return chartData;
};

export default function CardCurrent(props) {
  const navigate = useNavigate();
  const { profileData } = props;
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [pending, setPending] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteReady, setDeleteReady] = useState(false);

  const resultMessage =
    "Your camapign has been ended.  View will udpate in a few seconds.";

  useEffect(() => {
    const loadCampaign = async () => {
      const profile = profileData.data;
      const couponKey = profile.clientID + "-paid";
      const couponRecord = await readDocument("coupons", couponKey);
      if (couponRecord) {
        setCampaign(couponRecord.data);
        let redemptions = [];
        if ("redeems" in couponRecord.data) {
          const redeems = couponRecord.data.redeems;
          redemptions = extractChartData(redeems);
        }
        setChartData(redemptions);
      } else {
        const checkPending = await readDocument("approvals", couponKey);
        if (checkPending) {
          setPending(true);
        }
      }
      setLoading(false);
    };
    if (profileData) {
      loadCampaign();
    }
  }, [profileData]);

  const clickDelete = async () => {
    if (!deleteReady) {
      setShowAlert(true);
      setDeleteReady(true);
      setAlertVariant("danger");
      setAlertMessage("Are you sure?");
      setTimeout(() => {
        if (!deleteReady) {
          setDeleteReady(false);
          setShowAlert(false);
        }
      }, 4000);
      return;
    } else {
      setProcessing(true);
      setShowAlert(false);
      setAlertVariant("success");
      setAlertMessage(resultMessage);
      const killRecord = {
        couponKey: campaign.clientID + "-paid",
        endedBy: profileData.data.email,
        endedOn: makeISO(new Date()),
      };
      await addDocument("end-coupon", killRecord);
      setShowAlert(true);
      setTimeout(() => {
        setProcessing(false);
        setShowAlert(false);
        setCampaign(null);
      }, 4000);
    }
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Current Campaign</h3>
        </Row>
        <Row className="v-top-20 big-bottom">
          <Dots className="text-center" color="#88888" />
        </Row>
      </Col>
    );
  }

  if (pending) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Current Campaign</h3>
        </Row>
        <Row className="v-top-20 big-bottom">
          <p className="text-center">
            Your publish request is awaiting review.
          </p>
        </Row>
        <Row className="padded-row">
          <Button
            className="button-campaign"
            onClick={() => navigate("archive")}
          >
            View Past Campaigns
          </Button>
        </Row>
      </Col>
    );
  }

  if (!campaign) {
    return (
      <Col className="dash-card">
        <Row className="small-bottom">
          <h3>Current Campaign</h3>
        </Row>
        <Row className="big-bottom">
          <p className="text-center">
            Nothing running or pending review right now.
          </p>
        </Row>
        <Row className="campaign-row">
          <Button onClick={() => navigate("new")} className="button-campaign">
            Start New Campaign
          </Button>
        </Row>
        <Row className="padded-row">
          <Button
            className="button-campaign"
            onClick={() => navigate("archive")}
          >
            View Past Campaigns
          </Button>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Current Campaign</h3>
      </Row>
      <Row>
        <Image src={makeLink("images", campaign.file)} />
      </Row>
      {campaign.behavior === "coupon" ? (
        <React.Fragment>
          <Row className="headline-row">
            <h3 className="text-center v-margin-reset">{campaign.title}</h3>
          </Row>
          <Row>
            <h5 className="text-center v-top-10">{campaign.tag}</h5>
          </Row>
          <Row className="padded-row">
            <p className="text-center">{campaign.terms}</p>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row className="v-top-20">
            <p className="text-center">
              links to:&nbsp;&nbsp;
              <a href={campaign.link} target="_blank" rel="noreferrer">
                {campaign.link}
              </a>
            </p>
          </Row>
        </React.Fragment>
      )}

      <Row className="padded-row">
        <hr className="v-top-20" />
        <p className="text-center">
          Runs:&nbsp;<b>{niceDate(campaign.starts)}</b>
          &nbsp;&nbsp;-&nbsp;&nbsp;<b>{niceDate(campaign.ends)}</b>
        </p>
      </Row>
      <Row>
        <p className="text-center">
          Mobile App Target Radius:{" "}
          <b>{campaign.miles === 0 ? 25 : campaign.miles} miles</b>
        </p>
      </Row>
      <Row className="big-bottom">
        <p className="text-center">
          Estimated Target Population:{" "}
          <b>{campaign.pop ? campaign.pop : "all app"} users</b>
        </p>
      </Row>
      <Row>
        {chartData.length === 0 ? (
          <Image src={EmptyChart} />
        ) : (
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={chartData}
            options={options}
          />
        )}
      </Row>
      <Row className="wide-padding v-top-20">
        {showAlert && (
          <Alert variant={alertVariant}>
            <p className="text-center">{alertMessage}</p>
          </Alert>
        )}
        {processing ? (
          <Button variant="secondary">
            <Dots color="#ffffff" />
          </Button>
        ) : (
          <Button
            variant="secondary"
            onClick={() => clickDelete()}
            className={deleteReady ? "snagm-danger" : "snagm-color"}
          >
            End Campaign Now
          </Button>
        )}
      </Row>
    </Col>
  );
}
