import React, { useEffect, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { readDocument } from "../services/firestore";
import Dots from "react-activity/dist/Dots";
import { makeLink, niceDate } from "../services/svcFunctions";

export default function CardArchive(props) {
  const { profileData } = props;
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCampaign = async () => {
      if (!profileData) {
        return;
      }
      const profile = profileData.data;
      const couponKey = profile.clientID + "-paid";
      const couponRecord = await readDocument("history", couponKey);
      if (couponRecord) {
        setCampaignList(couponRecord.data);
      }
      setLoading(false);
    };
    loadCampaign();
  }, [profileData]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Campaigns Archive</h3>
        </Row>
        <Row>
          <Dots
            className="text-center"
            color="#888"
            size={32}
            speed={1}
            animating={true}
          />
        </Row>
      </Col>
    );
  }

  if (campaignList.length === 0) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Campaigns Archive</h3>
        </Row>
        <Row>
          <p className="text-center">no history data yet</p>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Campaigns Archive</h3>
      </Row>
      {campaignList.map((campaign, index) => {
        return (
          <Row key={index} className="big-bottom">
            <Col>
              <Image src={makeLink("images", campaign.file)} fluid />
            </Col>
            <Col>
              <h6>{campaign.nickname}</h6>
              <p>Started: {niceDate(campaign.starts)}</p>
              <p>
                Ended:{" "}
                {niceDate(campaign.endedOn ? campaign.endedOn : campaign.ends)}
              </p>
              <p>Radius: {campaign.miles === 0 ? 25 : campaign.miles} miles</p>
              <p>Targeted Users: {campaign.pop ? campaign.pop : "All"}</p>
              <p>Wallet Action: {campaign.wallets ? campaign.wallets : 0}</p>
              <p>
                Redemptions: {campaign.redemptions ? campaign.redemptions : 0}
              </p>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
}
